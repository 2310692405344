<template>
  <div
    v-if="form !== null"
    class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
  >
    <div class="width-100">
      <div class="container">
        <div class="container-fluid bg-white">
          <auto-dealer-form v-model="form" ref="autoForm" />
        </div>

        <div class="container-fluid bg-white">
          <div class="justify-content-end d-flex">
            <button
              type="submit"
              class="btn btn-success font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
              @click="submitForm"
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SET_BREADCRUMB,
  SET_BREADCRUMB_SHOW_BACK,
  SET_BREADCRUMB_ROUTE_BACK,
} from '@/core/services/store/breadcrumbs.module';
import { GET_ITEM } from '@/core/services/store/dealers.module';
import AutoDealerForm from '../../content/forms/AutoDealerForm';
export default {
  components: {
    AutoDealerForm,
  },

  data() {
    return {
      form: null,
    };
  },

  async beforeMount() {
    const id = this.$route.params?.id;
    this.$store.commit(SET_BREADCRUMB_SHOW_BACK, true);
    this.$store.commit(SET_BREADCRUMB_ROUTE_BACK, { name: 'general-dealers' });
    if (id) {
      this.form = await this.$store.dispatch(GET_ITEM, id);
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Автосалоны', route: { name: 'general-dealers' } },
        { title: this.form?.salon?.name },
      ]);
    } else {
      throw Error('Not id');
    }
  },

  methods: {
    submitForm() {
      this.$refs?.autoForm?.submit();
    },
  },
};
</script>
